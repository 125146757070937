@import "../partials/params";
@import "../partials/mixins";

.detail-article-post{

  .detail-head{
    padding-top: 40px;
    .title{
      padding: 10px 0 20px 0;
      h1{
        max-width: 995px;
      }
    }
    .dop-title{
      margin: 10px 0;
      h2{
        max-width: 890px;
        color: #666666;
        font-weight: normal;
      }
    }
    .author{
      .name{
        color: #666666;
        span{

        }
        a{
          text-decoration: none;
        }
      }
      .line-around{
        color: #666666;
      }
      .date{
        color: #666666;
      }
    }
    .social-media{
      padding: 10px 0;
      ul{
        &:after{
          content: '';
          display: table;
          clear: both;
        }
        li{
          float: left;
          &:last-child{
            a{
              margin-right: 0;
            }
          }
          a{
            padding: 10px 0;
            margin-right: 20px;
            text-decoration: none;
            transition: all .5s;
            &:hover{
              opacity: 0.7;
            }
            i{
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .detail-content{
    padding: 0 30px 0 0;
   &__img{
     .post-block{
      .img{
         img{

         }
       }
       .desc-for-img{
        p{
          span{
            color: #c5c5c5;
          }
        }
       }
     }
   }
   &__text{
      .wrap-over-inform{

        .detail-information{
          margin: 20px 0;
          &:after{
            content: '';
            clear: both;
            display: table;
          }
          .left{
            float: left;
            max-width: 50%;
            .wrap-inside-block{

              padding: 10px 0 10px 20px;
              border-left: 3px solid #4f7177;

              .img{

                img{
                  a{

                  }
                }
              }
              .desc{
                margin-top: 5px;
                p{
                  font-style: italic;
                }
              }
            }
          }
          .right{
            padding: 15px 0;
            .text{
              p{
                font-size: 17px;
                a{

                }
              }
            }
          }
        }
        p{
          //padding: 10px 0;
        }
        /*hr{
          border: none;
          border-bottom: 2px solid #fff200;
        }*/
        .dop-information{
          padding: 15px 0;
          border-top: 2px solid #fff200;
          margin-bottom: 10px;
        }
        .also-title{
          padding: 10px 0;
        }
      }
     .dop-information{
       p{

       }
     }
    }
  }
  .next-record-of-site{
    .title-widget-use{

    }
    .link-to-pop-articles{
      margin-bottom: 30px;
      ul{
        li{
          a{
            font-size: 16px;
            padding: 5px 0;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.style-block-p{
  padding: 10px 0;
  font-size: 17px;
  color: #4c4e4d;
}
.left-line{
  margin: 10px 0;
  padding: 10px 0 10px 20px;
  border-left: 3px solid #4f7177;
}