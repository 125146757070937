@import "../../partials/params";
@import "../../partials/mixins";

.posts-content{
  padding-right: 15px;
  margin: 20px 0;

}

.post-title{
  position: relative;
  height: 34px;
  overflow: hidden;
  margin: 15px 0;

  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    max-width: 744px;
    width: 100%;
    height: 3px;
    background: #fff200;
    margin-left: 10px;
  }
  h2{
    position: absolute;
    font-size: 23px;
    color: #4c4e4d;
    font-weight: bold;
    display: inline-block;
    padding: 0 20px 10px 0;
    z-index: 2;
    background: white;

  }
}
.post-title-2{
  margin: 15px 0;
  border-bottom: 3px solid #fff200;
  padding: 10px 0;
  h2{
    font-size: 23px;
    color: #4c4e4d;
    font-weight: bold;
    display: inline-block;
    background: white;

  }
}

.post{
  border-top: 1px solid #d1d1d1;
  @include media-w(767){
    padding: 10px 0;
  }
  .img{
    a{

    }
    img{
      float: right;
      display: inline;
    }

  }
  .content{
    padding: 12px;
    margin-bottom: 10px;
    @include media-w(767){
      padding: 5px;
    }
    @include media-w(402){
      font-size: 2px;
    }
    .title{
      h2{
        a{
          color: #4c4e4d;
          font-weight: bold;
          font-size: 18px;
          text-decoration: none;
          line-height: 22px;
          @include media-w(767){
            font-size: 15px;
          }
          @include media-w(576){
            padding-bottom: 3px;
            font-size: 13px;
            line-height: 16px;
          }
          @include media-w(512){
            padding-bottom: 6px;
          }
          @include media-w(402){
            font-size: 11px;
            line-height: 16px;
            padding-bottom: 3px;
          }
          @include media-w(361){
            font-size: 11px;
          }
        }
      }
    }
    .author{
      color: #4f7177;

      @include media-w(576){
        //font-size: 12px;
        //line-height: 16px;
      }
      @include media-w(512){
        font-size: 12px;
        line-height: 16px;
      }
      @include media-w(361){
        font-size: 10px;
      }
      .name{
        span{

        }
        a{
          color: #4f7177;
          text-decoration: none;
        }
      }
      .line-around{
        padding: 0 5px;
      }
      .date{

      }
    }
  }
}
