@import "../../partials/params";
@import "../../partials/mixins";


.right-bar{
  .wrap-over-block-link{

  }
  .start-article-title{
    h2{
      color: #4c4e4d;
      padding: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .blog-article-wrap{

    .blog-article{
      margin-bottom: 5px;
      &__link-img{
        display: block;
        @include media-w(991){
          display: none;
        }
        .img-link{
          width: 100%;
          text-decoration: none;
          color: #4f7177;
          .avatar{
            position: relative;
            max-width: 309px;
            width: 100%;
            height: 230px;
            background-size: cover !important;
            background-position: center !important;
            @include media-w(576){
              max-width: 100%;
              width: 100%;
              height: 325px;
            }
            span{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              i{
                font-size: 50px;
                background: yellow;
                padding: 8px 0;
                padding-left: 21px;
                padding-right: 17px;
                line-height: 30px;
              }
            }
          }
          .desc{

            p{
              padding: 10px 0;
            }
          }
        }
      }
      &__link-img-mobile{
        display: none;
        @include media-w(991){
          display: block;
        }
        border-top: 1px solid #c3c3c3;
        @include media-w(767){
          padding: 10px 0;
        }
        @include media-w(576){
          padding: 0px 0;
        }
        .img-link{
          .avatar{
            display: block;
            position: relative;
            span{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              i{
                padding-left: 13px;
                padding-right: 12px;
                font-size: 34px;
                background: yellow;
                line-height: 30px;
              }
            }
          }
        }
        .content{
          padding: 12px;
          margin-bottom: 10px;
          @include media-w(767){
            padding: 5px;
          }
          @include media-w(402){
            font-size: 2px;
          }
          .title{
            h2{
              a{
                color: #4c4e4d;
                font-weight: bold;
                font-size: 18px;
                text-decoration: none;
                line-height: 22px;
                @include media-w(767){
                  font-size: 15px;
                }
                @include media-w(576){
                  //font-size: 13px;
                  //line-height: 16px;
                  padding-bottom: 3px;
                }
                @include media-w(512){
                  font-size: 13px;
                  line-height: 16px;
                  padding-bottom: 6px;
                }
                @include media-w(402){
                  font-size: 11px;
                  line-height: 16px;
                  padding-bottom: 3px;
                }
                @include media-w(361){
                  font-size: 11px;
                }
              }
            }
          }
          .author{
            color: #4f7177;

            @include media-w(576){
              //font-size: 12px;
              //line-height: 16px;
            }
            @include media-w(512){
              font-size: 12px;
              line-height: 16px;
            }
            @include media-w(361){
              font-size: 10px;
            }
            .name{
              span{

              }
              a{
                color: #4f7177;
                text-decoration: none;
              }
            }
            .line-around{
              padding: 0 5px;
            }
            .date{

            }
          }
        }
      }
    }

    &__more{
      border-top: 1px solid #d1d1d1;
      text-align: right;
      padding: 10px 0;
      /*display: block;
      @include media-w(991){
        display: none;
      }*/
      a{
        font-size: 16px;
        color: #4f7177;
      }
    }
  }
}
