@import "../../partials/params";
@import "../../partials/mixins";


.cen-cont-bott{
  display: flex;
  justify-content: space-around;
  padding: 24px 0;
  @include media-w(991){
    padding: 13px 0;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 14px;
  }
  &__left{
    .title{
      padding-bottom: 8px;
      @include media-w(767) {
        padding-bottom: 3px;
      }
      @include media-w(576) {
        padding-bottom: 2px;
      }
      .h2{
        font-size: 20px;
        max-width: 320px;
        color: #4c4e4d;
        @include media-w(767){
          font-size: 16px;
        }
        a{
          text-decoration: none;
          color: #4c4e4d;
          font-weight: bold;
        }
      }
    }
    .desc{
      font-size: 14px;
      padding-bottom: 5px;
      display: block;
      @include media-w(767){
        display: none;
      }
      p{

      }
    }
    .author{
      span{
        @include media-w(767){
          font-size: 13px;
        }
      }
      a{
        @include media-w(767){
          font-size: 12px;
        }
        text-decoration: none;
      }
    }
  }
  &__right{
    padding-left: 50px;
    @include media-w(991){
      padding-left: 0px;
    }
    a{
      text-decoration: none;
      img{
        display: block;
      }
    }
  }
}