@import "../partials/params";
@import "../partials/mixins";

.title-widget-use{
  border-bottom: 3px solid #fff200;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 22px;
  padding: 10px 0;
}

.recomed-block-widget{
  text-align: right;
  padding: 5px 0 3px 0;
  border-top: 1px solid #e2e2e6;
  margin-top: 15px;
  a{
    text-decoration: none;
    .logo-recomend{
    }
    img{
      margin: 0 0 -3px 0;
    }
  }
}