@import "../partials/params";
@import "../partials/mixins";

.footer{
  background: #e9e7e8;
  padding: 20px 0 0 0;
  @include media-w(576){
    padding: 5px 0 0 0;
  }
  border-top: 6px solid #fff200;
  .wrap-content-footer{
    padding: 20px 0 30px 0;
    .footer-block-left{
      padding: 40px 16px;
      @include media-w(767){
        padding: 0px 16px;

      }
      .img-logo-footer{
        @include media-w(767){
          text-align: center;
        }
        a{

          img{

          }
        }
      }
    }
    .footer-block-center{
      padding: 8px 16px;
      .wrap-footer-menu{

        .menu-footer{
          margin: 6px 0;
          @include media-w(576){
            text-align: center;
          }
          ul{
            li{
              position: relative;
              display: inline-block;
              padding-right: 10px;
              margin-right: 10px;
              &:after{
                position: absolute;
                content: "\2022";
                display: inline-block;
                margin: 0 8px;
              }
              &:last-child{
                &:after{
                  content: none;
                }
              }
              a{
                color: #4f7177;
                text-decoration: none;
                transition: all .5s;
                &:hover{
                  color: #4c4e4d;
                }
              }
            }
          }
        }
      }
      .all-system{
        @include media-w(576){
          text-align: center;
        }
        &__title{
          p{
            color: #4c4e4d;
          }
        }
        &__link{
          color: #4f7177;
          text-decoration: none;
        }
      }
    }
    .footer-block-right{
      @include media-w(576){
        padding: 15px;
      }
      .media-block-footer{
        text-align: right;
        @include media-w(576){
          text-align: center;
        }
        .media-block-img{
          position: relative;
          margin-right: 40px;
          display: inline-block;
          a{
            font-size: 40px;
            font-weight: bold;
            text-decoration: none;
            color: #4c4e4d;
            @include media-w(991){
              font-size: 30px;
            }

            img{
              position: absolute;
              right: -34px;
              top: -23px;
            }
          }
        }
        div{
          color: #000;
          font-size: 13px;
          padding: 3px 0;
          a{
            color: #4f7177;
          }
        }
      }
    }
  }
}