@import "../partials/params";
@import "../partials/mixins";

.content{
  .content-title{
    margin: 30px 0 0 0;
    .title-content{
      position: relative;
      height: 50px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 50px;
        top: 24px;
        border-right: 4px solid #fff200;
        border-left: 4px solid #fff200;
        border-top: 4px solid #fff200;
        @include media-w(767){
          border-right: none;
          border-left: none;
        }
      }
      .parentSpan{

        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        text-align: center;
        .title{
          padding: 16px;
          background: white;
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          color: #4c4e4d;;
        }
      }
    }
  }
}