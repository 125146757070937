@import "../../partials/params";
@import "../../partials/mixins";

.subscribe-form{
  padding-top: 16px;
  text-align: center;
  &__form{
    background: #fff;
    border: 2px solid #4f7177;
    color: #4c4e4d;
    padding: 32px 16px 16px;
    position: relative;
    .img-logo-form{
      .img{
        position: absolute;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: -30px;
        img{
          padding: 10px;
          background: white;
        }
      }
    }
    .content-form{
      h2{
        font-weight: bold;
        color: #4f7177;
        padding: 5px 0 15px 0;
      }
      p{
        padding: 5px 0;
        color: #747675;

      }
    }
    .sub-form{
      padding: 5px 0;
      form{
        display: flex;
        input{
          flex-grow: 2;
          border: 1px solid #d1d1d1;
          font-size: .75em;
          outline: none;
          padding: 8px;
          transition: background-color .2s;
          &:focus{
            border: 1px solid #333;
            box-shadow: 0 0 5px #333;
          }
        }
        button{
          flex-grow: 1;
          background-color: #4f7177;
          border: 0;
          color: #ffffff;
          cursor: pointer;
          display: inline-block;
          font-size: 10px;
          letter-spacing: .09em;
          padding: 8px;
          text-transform: uppercase;
        }
      }
    }
  }
  &__desc-inform{
    margin: 20px 0;
    padding: 0px 10px;
    p{
      color: #747675;
      a{
        text-decoration: none;
        color: #81999e;
      }
    }
  }
}