@import "../../partials/params";
@import "../../partials/mixins";

$colorToLink: #98acae;

.compact-news{
  background: rgba(132, 132, 132, 0.14);
  border-top: 1px solid #9e9e9e;
  margin-bottom: 20px;
  margin-top: 5px;
  &__content{
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: flex;
    width: 100%;
    height: 100%;
    .compact-elements-flex{
      align-self: center;
      padding: 15px;
      h2{
        font-size: 25px;
        font-weight: bold;
        padding: 5px 0;
        @include media-w(767){
          font-size: 20px;
          padding: 3px 0;
        }
        @include media-w(576){
          text-align: center;
        }
        a{
          color: #4c4e4d;
          text-decoration: none;
        }
      }
      p{
        padding: 5px 0;
        color: #666666;
        @include media-w(767){
          font-size: 12px;
          padding: 3px 0;
        }

      }
      .detail{
        padding: 5px 0;
        @include media-w(576){
          text-align: center;
        }
        a{
          text-decoration: underline;
          color: #98acae;
        }
      }
    }
  }
  &__img{
    @include media-w(576){
     text-align: center;
    }
    a{
      display: block;
      img{
        float: right;
        display:  block;
        @include media-w(576){
          float: none;
          margin: 0 auto;
        }
      }
      .img{
        width: 100%;
        min-height: 310px;
        height: 100%;
        background-size: cover !important;
        background-position: center;
      }
    }
  }
}