@import "../partials/params";
@import "../partials/mixins";


.wrap-main-menu{
  background-color: #f2f3f2;
  //background-color: transparent;
  @include media-w(600){
    display: none;
  }
  .main-menu{
    position: relative;
    padding-bottom: 5px;
    .top-left-block{
      display: flex;
      flex-direction: row;
      .img-logo{
        flex-grow: 0.2;
        .img{
          padding: 8px 0px 0px 0px;
          @include media-w(991){
            //padding: 15px 15px 20px 15px;
          }
        }
        a{
          img{
              width: 180px;
              height: 50px;
          }
        }
      }
      .menu-top{
        flex-grow: 1.8;
        ul{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          li{
            &.item_6{
              @include media-w(1100){
                display: none;
              }
            }
            &.item_5{
              @include media-w(991){
                display: none;
              }
            }
            &.item_4{
              @include media-w(700){
                display: none;
              }
            }
            a{
              padding: 21px 0px;
              text-transform: uppercase;
              font-size: 11px;
              font-weight: bold;
              color: #4c4e4d;
              transition: all .5s;
              cursor: pointer;
              text-decoration: none;

             /* @include media-w(991){
                font-size: 10px;
              }*/
              &:hover{
                color: black;
                i{
                  color: black;
                }
              }
              &:active{
                background-color: white;
              }
            }
            &.nav-item{
              a.nav-link{
                i{

                }
              }
              .dropdown-menu{
                border: 0;
                border-radius: 0;
                top: 61px;
                box-shadow: none;
                border-bottom: 4px solid yellow;
                .dropdown-item{
                  display: block;
                  padding: 5px 0 5px 10px;
                }
              }
            }
          }
        }
      }
    }
    .top-right-block{
      &:after{
        content: '';
        display: table;
        clear: both;
      }
      i{
        font-size: 18px;
        color: #4c4e4d;
      }
      .social-icon{
        float: left;
        @include media-w(991){
          display: none;
        }
        .icons{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .icon{

            a{
              padding: 21px 10px;
              i{
                &.rotate_45{
                  transform: rotate(30deg);
                }
              }
            }
          }
        }
      }
      .sign-up-search{
        float: right;
        display: flex;
        flex-direction: row;
        .sign-up{
          padding: 21px 5px;
          a{
            i{

            }
          }
        }
        .search{
          padding: 21px 0px 15px 15px;
          a{
            i{

            }
          }
        }
      }
    }
  }
}