@import "../partials/params";
@import "../partials/mixins";

.mobile-menu{
  display: none;
  @include media-w(600){
    display: block;
  }
  .blog-wraper-logo{
    background: #fff200;
    .logo-img{
      position: relative;
      text-align: center;
      a{
        img{
          width: 150px;
          height: 40px;
          display: block;
          margin-top: 8px;
        }
      }
      .user-sign-up{
        position: absolute;
        right: 5px;
        bottom: 2px;
        .sign-up{
          a{
            padding: 15px;
            i{
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .blog-wrap-menu{
    background: #f1f3f2;
    position: relative;
    .menu-mob{
      ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li{
         flex-grow: 1;
         text-align: center;
          border-left: 1px solid #e4e6e5;

          &.open{
            background: white;
          }
          &:first-child{
            border-left: none;
          }
          a{
            padding: 5px 0;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            color: #4c4e4d;
            transition: all .5s;
            cursor: pointer;
            text-decoration: none;
            width: 100%;

            /* @include media-w(991){
               font-size: 10px;
             }*/
            &:hover{
              color: black;
              i{
                color: black;
              }
            }
            &:active{
              background-color: white;
            }
          }
          &.nav-item{
            a.nav-link{

              i{

              }
            }
            .dropdown-menu{
              text-align: left;
              height: 300px;
              overflow: scroll;
              border: 0;
              border-radius: 0;
              top: 30px;
              box-shadow: none;
              border-bottom: 4px solid yellow;
              width: 100%;
              .dropdown-item{
                border-bottom: 1px solid #efefef;
                display: block;
                padding: 8px 0 8px 10px;
              }
            }
          }
        }
      }
    }
  }
}