@import "../../partials/params";
@import "../../partials/mixins";

.center-cont-top{
  border-bottom: 1px solid #d1d1d1;
  &__head{
    padding-bottom: 24px;
    @include media-w(991){

      padding-bottom: 14px;
    }
    .img{
      a{
        img{

        }
      }
    }
  }
  &__body{

    .title{
      padding-bottom: 10px;
      @include media-w(767) {
        padding-bottom: 3px;
      }
      @include media-w(576){
        padding-bottom: 2px;
      }
      .h2{
        font-size: 36px;
        text-align: center;
        @include media-w(767){
          text-align: left;
          font-size: 18px;
        }
        @include media-w(576){
          font-size: 15px;
        }
        a{
          color: #4c4e4d;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
    .desc{
      color: #373938;
      text-align: center;
      padding-bottom: 8px;
      display: block;
      @include media-w(767){
        display: none;
      }
      @include media-w(576){
        padding-bottom: 4px;
      }
      p{
        font-size: 18px;
        @include media-w(576){
          font-size: 12px;
        }
      }
    }
  }
  &__footer{
    padding-bottom: 24px;
    @include media-w(576){
      padding-bottom: 10px;

    }
    .author{
      font-size: 14px;
      text-align: center;
      @include media-w(767){
        text-align: left;
        font-size: 13px;
      }
      @include media-w(576){
        font-size: 10px;
        text-align: left;
      }
      span{

      }
      a{
        text-decoration: none;
      }
    }
  }
}