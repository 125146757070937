@import "../../partials/params";
@import "../../partials/mixins";

.week-work{
  background: #f1f3f2;
  padding: 40px 0;
  margin: 15px 0;
  @include media-w(767){
    padding: 0 15px;
  }
}
.week-do{
  padding-top: 40px;
  &__title{
    padding: 5px;
    p{
      color: #4c4e4d;
    }
  }
  .paste-items{
    &:after{
      content: '';
      clear: both;
    }
    &__content{
      padding: 5px;
      @include media-w(767){
        float: left;
        margin-right: 5px;
      }
      .desc{
        strong{
          color: #4c4e4d;
        }
      }
    }
    &__bottom{
      padding: 5px;
      .button-select{

        .dropdown{

          button{
            color: #4c4e4d;
            background: #fff200;
            border: none;
            font-weight: bold;
          }
          .dropdown-menu{
            top: 41px;
            border: 0;
            min-width: 103px;
            .dropdown-item{
              display: block;
              padding-left: 15px;
              color: #4c4e4d;
              font-size: 12px;
              text-decoration: none;
            }
            i{
              position: absolute;
              right: 11px;
              top: -20px;
              color: white;
              font-size: 30px;
            }
          }
        }
      }
    }
  }

}
.audio-blok{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @include media-w(767){
    flex-direction: column;
  }
  &__left{
    margin-right: 35px;
    @include media-w(767){
      margin-right: 0px;
    }
    .img{
      text-align: center;
      @include media-w(767){
        padding: 40px 0;
      }
      a{
        img{
          max-width: 196px;
          width: 100%;
          @include media-w(767){
            max-width: 396px;
          }
        }
      }
    }
  }
  &__right{
    .head{

      .title{
        padding: 2px 0;
        p{

        }
      }
      .desc{
        padding: 3px 0;
        p{
          strong{

          }
        }
      }
      .text{
        padding: 5px 0;
        p{
          max-width: 425px;
        }
      }
    }
    .bottom-block{
      padding: 15px 0;
      .listen-audio{

      }
    }
  }
}