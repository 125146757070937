/* Style */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 22px; 
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 12px;
}

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: bold; }

a {
  display: inline-block;
  text-decoration: none;
  color: #4f7177;
  transition: all .5s;
  &:hover{
    color: #4c4e4d;
  }
}

body{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4c4e4d;
  font-size: 14px;

}

img{
  max-width: 100%;
  height: auto;
}
.h5{
  font-weight: 500;
  font-size: 18px;
  color: white;
  margin-bottom: 20px;
}
.selected{
  color: #22b2d5 !important;
}

h1{
  color: #4c4e4d;
  font-size: 36px;
  line-height: 36px;
}
h2{
  color: #4c4e4d;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}

h1, h2, h3, h4, h1{
  font-family: 'Open Sans', sans-serif;
}
.tc{
  text-align: center;
}
.tr{
  text-align: right;
}
.tl{
  text-align: left;
}
.a-h1{
  margin: 0 0 30px;
  font-size: 36px;
  line-height: 36px;
}
.widget_h1{
  margin-bottom: 20px;
  color: #FA7272;
}
.widget_h2{
  margin-bottom: 20px;
  color: #FA7272;
  font-size: 24px;
}
.c_o{
  color: #FA7272;
}
.c_o:hover{
  color: #FA7272;
  text-decoration: underline;
}
.mt10{
  margin-top: 10px;
}
.mt20{
  margin-top: 20px;
}
.mt30{
  margin-top: 30px;
}
.mt40{
  margin-top: 40px;
}
.mt50{
  margin-top: 50px;
}
.mb10{
  margin-bottom: 10px;
}
.mb20{
  margin-bottom: 20px;
}
.mb30{
  margin-bottom: 30px;
}
.mb40{
  margin-bottom: 40px;
}
.mb50{
  margin-bottom: 50px;
}
.dalee_h3{
  font-size: 20px;
  text-align: right;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.background-img{
  ///background: url("../img/background.png");
  width: 100%;
  height: 2000px;
}

i.fa-facebook{
   color: #325a97;
 }
i.fa-twitter{
  color: #00aced;
}

p{
  a{
    font-size: 16px;
    font-weight: 600;
    color: #4f7177;
    text-decoration: none;
    &:hover{
      color: #4f7177;
      text-decoration: none;
      opacity: 0.7;
    }
    &:active{
      color: #4f7177;
      text-decoration: none;
      opacity: 0.7;
    }
    &:focus{
      color: #4f7177;
      text-decoration: none;
      opacity: 0.7;
    }
  }
}