@import "../partials/params";
@import "../partials/mixins";


  .model-register-ponel{
    position: fixed;
    z-index: 100000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(25, 25, 25, 0.84);
    display: none;

  }

#wr-tabs{
  max-width: 464px;
  margin: 40px auto 0;
  height: 510px;
  overflow: auto;
  position: relative;
  .hidden-modal-icon{
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 18px 18px 18px;
    cursor: pointer;
    @include media-w(767){
      display: block;
    }
    i{
      font-size: 25px;
      color: red;
    }
  }
  @include media-w(767){
    height: 400px;
  }
  .tabs-ponel-for-goin{
    padding: 32px;
    background: white;
  }
  .logo-site{
    .img{
      text-align: center;
      padding: 15px 0;
      img{
          width: 320px;
      }
    }
  }
  .tabs{
    background-color: #fff;
    margin-bottom: 3px;
    padding: 0 65px;
    border-bottom: 1px solid #cfcfcf;
    @include media-w(576){
      padding: 0;
    }
    &:after{
      content: "";
      display: block;
      clear: both;
      height: 0;
    }
    .tab{
      float: left;
      cursor: pointer;
      padding: 10px 20px;
      margin-bottom: -1px;
      &:last-child{
        border-right: none;
      }
      &.active{
        background-color: white;
        border: 1px solid #cfcfcf;
        border-bottom: white;

      }
      &:hover{

      }
    }
  }
  .content{
    background-color: #fff;
    .social{
      padding: 15px 0 5px 0;
      ul{
        display: flex;
        justify-content: center;
        li{
          margin: 0 2px;
          padding: 15px;

          a{
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            text-decoration: none;
            color: #4c4e4d;
            i{

              font-size: 35px;
              color: white;
            }
          }
          &.icon_tw{
            background: #00aced;
            a{
              background: #00aced;
            }
          }
          &.icon_fc{
            background: #6583b1;
            a{
              background:  #6583b1;
            }
          }
          &.icon_you{
            background: #e5786a;
            a{
              background: #e5786a;
            }
          }
          &.icon_wf{
            background: #b6489f;
            a{
              background: #b6489f;
            }
          }
        }
      }
    }
    .tab-cont{
      display: none;
      padding: 15px 10px;
      .wrap-form{

        .form-login{
          form{
            .top-form-fields{
              .field-form{
                input{
                  width: 100%;
                  border: 1px solid #f1f1f1;
                  padding: 5px 10px;
                  &:first-child{
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .bottom-form-fields{
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              &.right-side{
                display: block;
                text-align: right;
              }
              .rem{
                input{
                  margin: 0;
                }
                label{
                  font-weight: 400;
                }
              }
              button{
                background: #4f7177;
                border: none;
                padding: 5px 10px;
                color: white;
                text-transform: uppercase;
              }
            }
          }
          .footer-form{
            margin-top: 10px;
            a{

            }
            span{

            }
          }
        }
      }
      &.active{
        display: block;
      }
    }
  }
}

