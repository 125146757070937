@import "params";


@mixin media-w($width){
	@media screen and(max-width: $width + 'px'){
		@content;
	}
}
@mixin media-mix-w($width){
	@media screen and(min-width: $width + 'px'){
		@content;
	}
}
@mixin media-h($height){
	@media screen and(max-height: $height + 'px'){
		@content;
	}
}
@mixin media-min-h($height){
	@media screen and(min-height: $height + 'px'){
		@content;
	}
}
@mixin media-maxw-minw($max-width,$min-width){
	@media screen and(max-width: $max-width + 'px')and(min-width: $min-width + 'px'){
		@content;
	}
}
@mixin media-maxh-minh($max-height,$min-height){
	@media screen and(max-height: $max-height + 'px')and(min-height: $min-height +'px'){
		@content;
	}
}

@mixin clear {
	margin: 0;
	padding: 0;
}
@mixin clr{
	clear: both;
}


@mixin clear-list{
	@include clear;
	list-style: none;
}

@mixin flex-center($dir: column){
	display: flex;
	flex-direction: $dir;
	justify-content: center;
	align-items: center;
}

@mixin package-color ($color: $yellow){
	& .item-value{
		color: $color;
	}
	& .tile-cost{
		border-bottom-color: $color !important; 
	}
}
%group-menu{
	background: red;
	color: white;
	padding: 14px 16px;
	border: none;
	border-radius: 0;
	@media screen and (max-width: 1200px){
		padding: 16px 16px;
	}
	@media screen and (max-width: 1091px){
		font-size: 14px;
	}
}
%h1{
	text-align: center;
	font-size: 280%;
	font-weight: bold;
	font-family: serif;
}
%h1-zagolovok{
	font-family: initial;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 30px
}
//Миксин для ссылок
@mixin links(){

	&:active{
		@content;
	}
	&:hover{
		@content;
	}
	&:focus{
		@content;
	}
}

//Миксин для картинок
@mixin bg($img, $type, $position, $bs: cover){
	background: url(../img/#{$img}.#{$type}) no-repeat $position;
	background-size: $bs; 
}
/* assets */
@mixin bg_img($path, $type, $style: null){
	background: url(#{$path}.#{$type}) no-repeat $style;
}
// @mixin bg_img($path, $type, $style: null){background: url(../assets#{$path}.#{$type}) no-repeat $style;}

@mixin br($price, $width, $color){
	@if($price == "all"){
		border: $width solid $color;
	}
	@else{
		border-#{$price}: $width solid $color;
	}
}

@mixin stili($c-hover,$c-active,$c-focus){
	&:hover{
		color: $c-hove;
	}
	&:active{
		color: $c-active;
	}
	&:focus{
		color: $c-focus;
	}
}

@mixin hover-drop($color,$fz,$doch-elem){
	&:hover{
		#{$doch-elem}{
			color: $color;
			font-size: $fz;
		}
	}
}
//для нижнего подчеркивания
@mixin after-hov($color,$h-siz){

	&:after{
		content: "";
		bottom: 0;
		left: 0;
		position: absolute;
		display: block;
		width: 100%;
		background:$color;
		height: $h-siz;		
	}
}

