@import "../../partials/params";
@import "../../partials/mixins";

.articles{

  .article{
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 15px;
    &__head{
      padding-bottom: 24px;
      @include media-w(991){
        padding-bottom: 14px;
      }
      @include media-w(767){
        padding-bottom: 10px;
      }
      .img{
        a{
          img{

          }
        }
      }
    }
    &__body{
      .title{

        .h2{
          font-size: 20px;
          color: #4c4e4d;
          font-weight: bold;
          @include media-w(767){
            font-size: 15px;
          }
          @include media-w(576){
            padding-bottom: 1px;
            font-size: 13px;
            line-height: 16px;
          }
          a{
            color: currentColor;
            text-decoration: none;
          }
        }
      }
      .desc{
        font-size: 14px;
        display: block;
        @include media-w(767){
          display: none;
        }
        p{

        }
      }
    }
    &__footer{
      padding-bottom: 24px;
      @include media-w(767){
        padding-top: 2px;
      }
      .author{
        font-size: 13px;
        @include media-w(767){
          font-size: 13px;
        }
        span{

        }
        a{
          text-decoration: none;
        }
      }
    }
  }
}