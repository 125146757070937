@import "../../partials/params";
@import "../../partials/mixins";

.wrap-popular-articles{
  .popular-articles{

  }
  .pop-content{
    text-decoration: none;
    .img{
      img{

      }
    }
    .title{
     padding: 5px 0 3px 0;
      span{
        font-size: 18px;
        color: #4f7177;
      }
    }
    span{
      color: #888;
    }
  }
}