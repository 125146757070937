@import "../../partials/params";
@import "../../partials/mixins";

.main-button-more-posts{
  text-align: center;
  margin: 10px 0;
  a{
    display: block;
    color: white;
    background: #4f7177;
    padding: 15px 0;
    text-transform: uppercase;
    text-decoration: none;
    transition: background .5s;
    &:hover{
      background: black;
    }

  }
}