@import "../partials/params";
@import "../partials/mixins";

#search-top{
  display: none;
}
.wrap-search{
 /* position: absolute;
  bottom: -69px;
  left: 0;
  right: 0;*/
  border-bottom: 1px solid #d1d1d1;
  background: white;
  .search{
    padding: 0 64px;
    form{
      input{
        background-color: transparent;
        border: 0;
        opacity: .333;
        display: block;
        padding: 8px;
        outline: 0;
        width: 100%;
        padding-right: 75px;
        text-overflow: ellipsis;
        font-size: 1.5em;
        height: 68px;
        color: #4c4e4d;
        cursor: pointer;
      }
      button{
        margin-top: 20px;
        padding: 5px 20px;
        background-color: #4f7177;
        color: white;
        text-transform: uppercase;
        outline: 0;
        cursor: pointer;
        z-index: 900;
        border: none;
      }
    }
  }
}